<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.profile.crud.edit }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.profile.form.user }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="userOptions"
                      v-model="profile.user"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.profile.user.$error && !$v.profile.user.required"
                  >
                    {{ $lang.profile.validation.required.user }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.name.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.name }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.name"
                      class="form-control"
                      v-model="profile.name"
                  />

                  <small
                      class="error"
                      v-if="$v.profile.name.$error && !$v.profile.name.required"
                  >{{ $lang.profile.validation.required.name }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.title.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.title }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.title"
                      class="form-control"
                      v-model="profile.title"
                  />

                  <small
                      class="error"
                      v-if="$v.profile.title.$error && !$v.profile.title.required"
                  >{{ $lang.profile.validation.required.title }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.company.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.company }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.company"
                      class="form-control"
                      v-model="profile.company"
                  />

                  <small
                      class="error"
                      v-if="
                      $v.profile.company.$error && !$v.profile.company.required
                    "
                  >{{ $lang.profile.validation.required.company }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.profile.form.companyType }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="companyTypeOptions"
                      v-model="profile.companyType"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.profile.companyType.$error &&
                      !$v.profile.companyType.required
                    "
                  >
                    {{ $lang.profile.validation.required.companyType }}
                  </small>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol col="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.profile.form.industry }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="industryOptions"
                      v-model="profile.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.profile.industry.$error &&
                      !$v.profile.industry.required
                    "
                  >
                    {{ $lang.profile.validation.required.industry }}
                  </small>
                </div>
              </CCol>

              <CCol col="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.profile.form.subIndustry }}
                    <required_span/>
                  </label>
                  <v-select
                      multiple
                      :options="subIndustryOptions"
                      v-model="profile.subIndustry"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.profile.subIndustry.$error &&
                      !$v.profile.subIndustry.required
                    "
                  >
                    {{ $lang.profile.validation.required.subIndustry }}
                  </small>
                </div>
              </CCol>
            </CRow>

            <div
                class="form-group"
                :class="{ 'form-group--error': $v.profile.address1.$error }"
            >
              <label class="form__label"
              >{{ $lang.profile.form.address1 }}
              </label>
              <textarea
                  :placeholder="$lang.profile.form.address1"
                  class="form-control"
                  v-model="profile.address1"
              />

              <small
                  class="error"
                  v-if="
                  $v.profile.address1.$error && !$v.profile.address1.required
                "
              >{{ $lang.profile.validation.required.address1 }}</small
              >
            </div>

            <CRow>
              <CCol col="4">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.profile.form.country }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="countryOptions"
                      v-model="profile.country"
                      @option:selected="loadState"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.profile.country.$error && !$v.profile.country.required
                    "
                  >
                    {{ $lang.profile.validation.required.country }}
                  </small>
                </div>
              </CCol>

              <CCol col="4">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.state.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.state }}
                    <required_span
                    />
                  </label>
                  <v-select
                      :options="StateOptions"
                      v-model="profile.state"
                      @option:selected="loadCity"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="$v.profile.state.$error && !$v.profile.state.required"
                  >{{ $lang.profile.validation.required.state }}</small
                  >
                </div>
              </CCol>

              <CCol col="4">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.city.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.city }}
                    <required_span
                    />
                  </label>
                  <v-select :options="cityOptions" v-model="profile.city">
                  </v-select>
                  <small
                      class="error"
                      v-if="$v.profile.city.$error && !$v.profile.city.required"
                  >{{ $lang.profile.validation.required.city }}</small
                  >
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol col="4">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.profile.skills.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.profile.form.skills }}
                    <required_span
                    />
                  </label>
                  <v-select :options="skillsOptions" v-model="profile.skills">
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.profile.skills.$error && !$v.profile.skills.required
                    "
                  >{{ $lang.profile.validation.required.skills }}</small
                  >
                </div>
              </CCol>

              <CCol col="4">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.profile.form.website }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.websitePlaceHolder"
                      class="form-control"
                      v-model="profile.website"
                  />
                </div>
              </CCol>
            </CRow>

            <div
                class="form-group"
                :class="{ 'form-group--error': $v.profile.status.$error }"
            >
              <label class="form__label"
              >{{ $lang.profile.form.status }}
                <required_span
                />
              </label>
              <v-select :options="statusOptions" v-model="profile.status">
              </v-select>
              <small
                  class="error"
                  v-if="$v.profile.status.$error && !$v.profile.status.required"
              >{{ $lang.profile.validation.required.status }}</small
              >
            </div>

            <CRow>
              <CCol col="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.profile.form.latitude }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.latitude"
                      class="form-control"
                      v-model="profile.latitude"
                  />
                </div>
              </CCol>
              <CCol col="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.profile.form.longitude }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.profile.form.longitude"
                      class="form-control"
                      v-model="profile.longitude"
                  />
                </div>
              </CCol>
            </CRow>

            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label"
              >{{ $lang.profile.form.isVerified }}
                <required_span/>
              </label
              >&nbsp;&nbsp;
              <input
                  type="checkbox"
                  :placeholder="$lang.profile.form.isVerified"
                  :value="1"
                  @change="changedIsVerified"
                  v-model="profile.isVerified"
              />
            </div>

            <div class="form-group">
              <label class="form__label">{{ $lang.profile.form.image }}</label>
              <img alt="Profile Image" :src="profileImage" class="imageDimenstion"/>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  profile,
  industry,
  users,
  profileSubIndustry,
  companytype,
  country,
  state,
  city,
  skills,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "ProfileEdit",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      submitted: false,
      Disabled: true,
      err_msg: "",
      profileImage: "",
      selectedSkills: [],
      dismissCountDown: 0,
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      companyTypeOptions: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      statusOptions: [],
      userOptions: [],
      getIndustries: [],
      skillsOptions: [],
      self: this,
      module: profile,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users,
      moduleCompanyType: companytype,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      moduleSkills: skills,
      profile: {
        name: "",
        title: "",
        company: "",
        subIndustry: "",
        companyType: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        industry: "",
        user: "",
        status: "",
        skills: "",
      },
    };
  },
  validations: {
    profile: {
      name: {
        required,
      },
      title: {
        required,
      },
      company: {
        required,
      },
      industry: {
        required,
      },
      subIndustry: {
        required,
      },
      user: {
        required,
      },
      companyType: {
        required,
      },
      country: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      address1: {
        required,
      },
      status: {
        required,
      },
      skills: {
        required,
      },
    },
  },
  mounted() {
    const statusType = {
      PENDING: "Pending",
      VERIFIED: "Verified",
      BLOCK: "Block",
    };
    let self = this;

    self.statusOptions.push(
        {value: 1, label: statusType.PENDING},
        {value: 2, label: statusType.VERIFIED},
        {value: 3, label: statusType.BLOCK}
    );
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;

    axios.get(this.listUrlApi(this.moduleSkills)).then((response) => {
      response.data.data.map(function (value, key) {
        self.skillsOptions.push({value: value.id, label: value.title});
      });
    });

    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          // eslint-disable-next-line no-empty
          if (response.data) {
            const responseData = response.data.profile;

            responseData.skills.map(function (value, key) {
              self.selectedSkills.push({
                value: value.id,
                label: value.title,
              });
            });
            self.profile.skills = self.selectedSkills;

            self.profile.name = responseData.name;
            self.profile.title = responseData.title;
            self.profile.company = responseData.company;
            self.profile.address1 = responseData.address1;

            self.profile.industry = {
              value: responseData.industryId,
              label: responseData.industryTitle,
            };
            self.profile.status = {
              value: responseData.industryId,
              label: responseData.industryTitle,
            };

            const postData = {id: responseData.industryId};
            axios
                .post(this.listUrlApi(this.moduleSubIndustry), postData)
                .then((response) => {
                  self.subIndustryOptions = [];
                  response.data.map(function (value, key) {
                    self.subIndustryOptions.push({
                      value: value.value,
                      label: value.label,
                    });
                  });
                });

            axios
                .post(this.listUrlApi(this.moduleState), {
                  countryId: responseData.countryId,
                })
                .then((response) => {
                  //    self.StateOptions = [];
                  response.data.data.map(function (value, key) {
                    self.StateOptions.push({value: value.id, label: value.name});
                  });
                });

            self.cityOptions = [];
            axios
                .post(this.listUrlApi(this.moduleCity), {
                  stateId: self.profile.state.value,
                })
                .then((response) => {
                  response.data.data.map(function (value, key) {
                    self.cityOptions.push({value: value.id, label: value.name});
                  });
                });

            self.profileImage = responseData.image;

            self.profile.state = {
              value: responseData.stateId,
              label: responseData.stateName,
            };

            self.profile.city = {
              value: responseData.cityId,
              label: responseData.cityName,
            };
            self.profile.status = {
              value: responseData.isVerifiedId,
              label: responseData.profileStatus,
            };
            self.profile.isVerified = responseData.isVerifiedId;

            if (responseData.chatId !== "") {
              self.profile.chatId = responseData.chatId;
            }
            if (responseData.chatPassword !== "") {
              self.profile.chatPassword = responseData.chatPassword;
            }

            responseData.subIndustry.map(function (value, key) {
              self.selectedIndustry.push({
                value: value.id,
                label: value.title,
              });
            });
            self.profile.subIndustry = self.selectedIndustry;

            self.profile.user = {
              value: responseData.userId,
              label: responseData.name,
            };

            self.profile.companyType = {
              value: responseData.companyTypeId,
              label: responseData.companyTypeTitle,
            };
            self.profile.country = {
              value: responseData.countryId,
              label: responseData.countryName,
            };

            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });

    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        // self.industryLists.push({value: value.id, label: value.title});
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get(this.listUrlApi(this.moduleCompanyType)).then((response) => {
      response.data.data.map(function (value, key) {
        self.companyTypeOptions.push({value: value.id, label: value.title});
      });
    });

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });

    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
  },
  methods: {
    loadState() {
      let self = this;
      const postData = {countryId: self.profile.country.value};
      self.profile.state = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.profile.state.value};
      self.profile.city = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },

    changedIsVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.profile.isVerified = 1;
      } else {
        self.profile.isVerified = 0;
      }
    },
    getSubIndustry() {
      let self = this;
      if (self.profile.industry.value > 0) {
        const postData = {industry_id: self.profile.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },

    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.profile.$touch();
      if (this.$v.profile.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          let username,
              website;
          // self.profile.subIndustry.map(function (value, key) {
          //     self.getIndustries.push({
          //         'id': value.value,
          //     })
          // });

          self.profile.subIndustry.map(function (value, key) {
            self.getIndustries.push({
              value: value.value,
            });
          });

          if (self.profile.user.value !== "") {
            username = self.profile.user.value;
          }
          if (self.profile.website !== "") {
            website = self.profile.website;
          }
          const postData = {
            userId: username,
            name: self.profile.name,
            title: self.profile.title,
            company: self.profile.company,
            industry: self.profile.industry.value,
            subindustry: self.getIndustries,
            companyType: self.profile.companyType.value,
            address1: self.profile.address1,
            country: self.profile.country.value,
            state: self.profile.state.value,
            city: self.profile.city.value,
            website: website,
            latitude: self.profile.latitude,
            longitude: self.profile.longitude,
            isVerified: self.profile.isVerified,
            chatId: self.profile.chatId,
            status: self.profile.status.value,
            chatPassword: self.profile.chatPassword,
          };
          axios
              .post(this.updateUrlweb(this.module, self.editId), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/profile"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/profile"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.imageDimenstion {
  width: 150px;
  height: 150px;
}

.hasError label {
  color: red;
}
</style>
